import { Container, Grid, Typography } from '@material-ui/core';
import {useStyles} from './Footer.styles';
const Footer = () => {
    const classes = useStyles();
  
    return (
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Grid container >
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.footerText}>
                © {new Date().getFullYear()} Subtle Scale
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </footer>
    );
  };
  
  export default Footer;